$color-primary: #2D5C94;	// Main Primary color */
$color-primary-lightest: #9A9B9C;
$color-primary-lighter: #7B92AD;
$color-primary-darker: #093F81;
$color-primary-darkest: #002E66;

$color-secondary: #359F8D;

//$color-primary: #EB9C17
//$color-primary-lighter: #FEBB4A
$color-white: lighten($color-primary-lightest, 34%);
$color-black: darken($color-primary-darkest, 10%);
$color-blue: #1A93CD;
$color-gray: #333;
$color-error: #f00;

@mixin  scrollbar() {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5; }

  &::-webkit-scrollbar {
	  width: 6px;
	  background-color: #F5F5F5; }

  &::-webkit-scrollbar-thumb {
  	background-color: $color-primary; } }

@mixin centered() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

